import utils from './lib/utils';
import loader from './lib/lazyLoad';
import common from './modules/common';
import lookup from './modules/lookup';
import tabs from './modules/tabs';
import urls from './lib/urls';
import blog from './modules/blog';
import bigIntroSearch from './modules/big-intro-search';

common();
lookup();
tabs();
bigIntroSearch();
blog();

$(function() {
    if (navigator.onLine == false) {
        $('.offline-notifications').css('display', 'flex');
    }

    $('.retry-btn').on('click', function() {
        if (new URL(window.location.href).pathname === '/offline') {
            return window.location.href = '/';
        }
        location.reload();
    });

    try {
        showLessPartsOnSmallScreens();
    } catch(e) {
        // Operator not supported or no breakpoint set.
    }
    
    $(window).on('resize', utils.debounce(function () {
        try {
            showLessPartsOnSmallScreens();
        } catch(e) {
            // Operator not supported or no breakpoint set.
        }
    }, 200));

    let $header = $('.is-home-header');
    let $headerSearch = $header.find('.search-form');
    let $headerSearchInput = $headerSearch.find('input[type=text]');
    let $bigSearch = $('.intro-big-search');
    let $bigSearchInput = $('.intro-big-search input[type=text]');
    

    $('.learn-more-item .icon').each(function(index, item) {
        loader.lazyLoadImage($(item));
    });

    $.ajax({
        url: '/ajax/home',
        method: 'GET',
        success: function (responseData) {
            $(responseData).insertAfter('.learn-more-section');
        }
    });

    $(window).on('scroll', function() {
        let scroll = $(window).scrollTop();
        let searchPos = $bigSearch.position();
        let $homeIntro = $('.public-home-intro');
        if (scroll > searchPos.top) {
            $bigSearchInput.val($headerSearchInput.val());
            $('.intro-big-search-holder').css('visibility', 'hidden');
            $headerSearch.removeClass('hide');
            $header.css('background-color', $homeIntro.css('background-color'));
            $header.css('background-image', $homeIntro.css('background-image'));
        } else {
            $headerSearchInput.val($bigSearchInput.val());
            $('.intro-big-search-holder').css('visibility', 'visible');
            $headerSearch.addClass('hide');
            if (utils.checkScreenSize('>=', 'tablet')) {
                $header.css('background-color', 'rgba(255,255,255,0)');
                $header.css('background-image', 'none');
            } else {
                $header.css('background-color', $homeIntro.css('background-color'));
                $header.css('background-image', $homeIntro.css('background-image'));
            }
        }
        if (scroll == 0) {
            $header.css('background-color', 'rgba(255,255,255,0)');
            $header.css('background-image', 'none');
        }
    });
    
    $(document).on('click', '#show-all-categories', function() {
        let isInit = $('.browse-list.parametric-browse').attr('data-init');
        let $this = $(this);
        if (isInit) {
            $('.browse-list.parametric-browse li:not(.template)').removeClass('hide');
            $this.addClass('hide');
            $('#show-less-categories').removeClass('hide');
        } else {
            let $template = $('.browse-list.parametric-browse li.template');
            $('#show-less-categories .loading').css('display', 'block');
            $.ajax({
                url: '/ajax/classList',
                method: 'GET',
                success: function (responseData) {
                    let data = responseData.data;
                    let order = responseData.order;
                    let loaded = $('.browse-list.parametric-browse li:not(.template)').length;
                    $.each(order.slice(loaded), function(_i, name) {
                        let $clone = $template.clone();
                        $clone.removeClass('template');
                        let className = utils.toClassName(name);
                        switch (className) {
                            case 'amplifiers': className = 'amplifiercircuits'; break;
                            case 'rfmicrowavedevices': className = 'rfandmicrowave'; break;
                            default: break;
                        }
                        let iconName = `icon-${className}`;
                        $clone.find('a').prop('href', urls.parametric.build({ path: '/parametric', Class: name }));
                        $clone.find('span').addClass(iconName);
                        $clone.find('p').html(name + '<br><small>' + utils.numberFormat(data[name], 0) + '</small>');
                        $('.browse-list.parametric-browse').append($clone);
                        $('.browse-list.parametric-browse').attr('data-init', true);

                        $('.browse-list.parametric-browse li:not(.template)').removeClass('hide');
                        $this.addClass('hide');
                        $('#show-less-categories').removeClass('hide');
                    });
                }
            }).always(function() {
                $('#show-less-categories .loading').css('display', 'none');
            });
        }
    });
    $(document).on('click', '#show-less-categories', function() {
        $('.browse-list.parametric-browse li.generated-entry').addClass('hide');
        $(this).addClass('hide');
        $('#show-all-categories').removeClass('hide');
    });
});

function showLessPartsOnSmallScreens() {
    if (utils.checkScreenSize('<=', 'tablet')) {
        $('.popular-parts-results').map(function () {
            return $(this).find('li').slice(13, -1).map(function () {
                $(this).hide();
            });
        });
    } else {
        $('.popular-parts-results').map(function () {
            return $(this).find('li').show();
        });
    }
}
