import utils from '../lib/utils';
import global from './global';
import '../../vendor/jquery.cookie';
import '../../vendor/jquery.liteTooltip.js';
import '../../vendor/jquery-select.js';

export default function() {
    $(function() {
        global();

        if ($('.select').length) {
            $('.select').selectOverlap();
        }

        // init lite tooltips
        $('.j-dropdown').each(function (_index, element) {

            let id = $(element).attr('id'),
                action = $(element).data('action') || 'click',
                delay = $(element).data('delay') || 0;

            $('#' + id).liteTooltip({
                action: action,
                delay: parseInt(delay)
            });
        });

        // check if partsio images are loaded
        $('.j-load-check').each(function (_index, img) {

            let $img = $(img);

            $('<img/>').on('error', function () {

                let id = $img.data('rel');

                $img.remove();
                $('#' + id).show();

            }).attr('src', $img.attr('src'));
        });
        
        //cookie notifications, set cookie if we shown notification and user accept it
        if ($('#cookie-notifications-btn').length) {

            $('#cookie-notifications-btn').click(function () {
                let time = 525600 * 60000, // one year in miliseconds
                    vdate = new Date();

                vdate.setTime(vdate.getTime() + time);

                document.cookie = 'fc_cookie_notification_accepted=true; expires=' + vdate + '; path=/';

                $('.cookie-notifications').hide();
                $('body').removeClass('euro-message-active');
            });
        }

        $('.ie-message').on('click', '.j-ie-close', function () {
            let time = 525600 * 60000, // one year in miliseconds
                vdate = new Date();

            vdate.setTime(vdate.getTime() + time);
            document.cookie = 'fc_ie_closed=true; expires=' + vdate + '; path=/';
            $('.ie-message').slideUp();
        });

        // don't show feedback links if IE9 or less
        if ($('html').hasClass('ie9') || $('html').hasClass('lt-ie9')) {
            $('.j-hide-if-oldIE').hide();
        }

        $(document).on('click', '.onclick', function() {
            let $this = $(this);
            eval($this.attr('data-onclick'));
        });

        $('.onerror').on('error', function() {
            let $this = $(this);
            eval($this.attr('data-onerror'));
        });

        if (typeof $.cookie !== 'undefined' && typeof window.FC !== 'undefined' && window.FC.site === 'findchips') { 
            if ($.cookie('fc_timezone') != Intl.DateTimeFormat().resolvedOptions().timeZone) {
                $.cookie('fc_timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, {path: '/'});
            }
            if ($.cookie('fc_locale') != navigator.language) {
                $.cookie('fc_locale', navigator.language, {path: '/'});
            }
        }

        $('#search, #search-insight, #search-part, #parametric').submit(function (e) {
            let val = $(this).find('input[type=text]').val(),
                $error = $(this).find('.search-input-error');

            if (utils.validTerm(val)) {
                let action = $(this).prop('action');
                if (action.includes('/detail')) {
                    e.preventDefault();
                    location.href = action + '/' + encodeURIComponent(val.toLowerCase());
                }
                $error.hide();
                return true;
            } else {
                $error.show();
                $('.tt-menu, .autocomplete-holder').hide();
                return false;
            }
        });

        // remove error message on typing
        $('#search, #search-insight, #search-part, #parametric').keyup(function (e) {
            $(this).find('.search-input-error').hide();
            if (e.keyCode === 13) {
                $(this).submit();
            }
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/fc-sw.js?v=' + __VERSION__ + '&e=' + __ENV__);
        }
    });
}